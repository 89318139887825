import get from 'lodash/get';

import { useStaticProps } from '../components/context/static-props';
import types from '../components/factory/component-types';
import { DEVICE_TYPES } from '../constants';
import { useSearch } from './context';

const { DESKTOP } = DEVICE_TYPES;
const LABELS_PATH = {
  CLIENT_SIDE: 'map_configuration.filters.available_filters.labels',
  DEFAULT: 'filtering_options.labels',
};

const findAvailableFilters = ({ type }) => type === types.AVAILABLE_FILTERS;

const getDesktopPath = ({ sidebar: { components = [] } = {} }) => {
  const availableFiltersIndex = components.findIndex(findAvailableFilters);

  return availableFiltersIndex > -1 ? `sidebar.components[${availableFiltersIndex}].labels` : undefined;
};

const useFilterLabels = () => {
  const { deviceType } = useStaticProps();
  const searchContext = useSearch();

  if (deviceType === DESKTOP) {
    const path = getDesktopPath(searchContext);
    const clientSideLabels = get(searchContext, LABELS_PATH.CLIENT_SIDE, {});

    return get(searchContext, path, clientSideLabels);
  }

  return get(searchContext, LABELS_PATH.DEFAULT, {});
};

export default useFilterLabels;
