import React, { useState } from 'react';
import { shape, string, object } from 'prop-types';

import classnames from 'classnames';
import AndesModal from '@andes/modal';

import getIcon from '../../../components-v2/icons';

const namespace = 'ui-search-top-switch-filter';

const ActionTopSwitchFilter = ({ action, className = '', classNameModal = '' }) => {
  const { icon, url, title, type } = action;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onIframeLoad = (event) => {
    const iframe = event.target;
    const modalContentEl = document.querySelector('.andes-modal__content');

    iframe.style.cssText = `
      height: ${modalContentEl?.getBoundingClientRect().height}px;
    `;
  };

  switch (type) {
    case 'modal':
      return (
        <>
          <button
            type="button"
            className={`${namespace}-action${className ? ` ${className}` : ''}`}
            onClick={() => setIsModalOpen(true)}
            data-testid="top-switch-filter-action"
          >
            {getIcon({ iconId: icon?.id })}
          </button>
          <AndesModal
            title={title}
            type="full"
            className={classnames(`${namespace}-modal${classNameModal ? ` ${classNameModal}` : ''}`)}
            closeButtonSrLabel="Cerrar"
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            data-testid="top-switch-filter-modal"
          >
            <iframe id="modalIframe" title="Modal Iframe" src={url} onLoad={(event) => onIframeLoad(event)} />
          </AndesModal>
        </>
      );
    case 'redirect':
      return (
        <button
          type="button"
          className={`${namespace}-action${className ? ` ${className}` : ''}`}
          onClick={() => {
            window.location.href = url;
          }}
          data-testid="top-switch-filter-action"
        >
          {getIcon({ iconId: icon?.id })}
        </button>
      );

    default:
      return null;
  }
};

ActionTopSwitchFilter.propTypes = {
  action: shape({
    icon: object,
    title: string,
    type: string,
    url: string,
  }),
  className: string,
  classNameModal: string,
};

export default ActionTopSwitchFilter;
