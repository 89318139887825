import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { string, bool, func, oneOf } from 'prop-types';

import classnames from 'classnames';

import Link from '../../components-v2/link';
import useFilterLabels from '../../hooks/use-filter-labels';
import { LAYOUTS } from '../../constants';
import { trackClickEvent } from '../map/track';
import { useSearch } from '../../hooks/context';
import useMapConfig from '../map/hooks/use-map-config';

const namespace = 'ui-search-animated-switch';

const AnimatedSwitch = forwardRef(
  ({ className, href, checked, onClick, size, usePreventDefault, linkTag, updateOnCheckedChange }, ref) => {
    const [isChecked, setIsChecked] = useState(checked);
    const [animate, setAnimate] = useState(false);
    const switchEl = useRef(null);
    const { apply } = useFilterLabels();
    const { layout_options } = useSearch();
    const { searchTrack } = useMapConfig();
    const track = {
      melidata: {
        melidata_track: {
          ...searchTrack,
          type: 'view',
        },
      },
    };

    const handleClick = (e) => {
      if (usePreventDefault && e) {
        e.preventDefault();

        return;
      }

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      setIsChecked(!isChecked);

      if (!animate) {
        setAnimate(true);
      }

      if (onClick) {
        onClick(e);
      }
    };

    useImperativeHandle(ref, () => ({
      clickEvent: () => {
        handleClick();
      },
    }));

    useEffect(() => {
      if (updateOnCheckedChange) {
        setIsChecked(checked);
      }
    }, [checked, updateOnCheckedChange]);

    return (
      <Link
        className={classnames(className, namespace, { [`${namespace}--${size}`]: !!size })}
        href={href}
        onClick={handleClick}
        linkTag={linkTag}
        ariaLabel={apply}
        title="animated switch"
      >
        <span
          ref={switchEl}
          className={classnames(`${namespace}__switch-bar ${namespace}__switch-bar--${isChecked ? 'on' : 'off'}`, {
            animated: animate,
          })}
        />
      </Link>
    );
  },
);

AnimatedSwitch.propTypes = {
  checked: bool.isRequired,
  className: string,
  href: string.isRequired,
  linkTag: string,
  size: oneOf(['small']),
  updateOnCheckedChange: bool,
  usePreventDefault: bool,
  onClick: func,
};

AnimatedSwitch.defaultProps = {
  className: null,
  onClick: () => {},
  linkTag: 'a',
  size: null,
  updateOnCheckedChange: false,
  usePreventDefault: false,
};
AnimatedSwitch.displayName = 'AnimatedSwitch';

export default AnimatedSwitch;
