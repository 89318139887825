import React from 'react';
import { string, bool, shape, array } from 'prop-types';

import useFilterSearch from '../../hooks/use-filter-search';
import HighlightLabel from '../card/highlight/highlight-label';
import AnimatedSwitch from '../animated-switch/animated-switch';
import ActionTopSwitchFilter from './components/actions';
import StyledLabelWrapper from '../styled-label/styled-label';
import { FilterTooltip } from '../../components-v2/sidebar/components/filters-tooltip/mobile';

const namespace = 'ui-search-top-switch-filter';

const TopSwitchFilter = ({ id, highlight, is_on, url, results_text, label, tooltip, actions }) => {
  const filterSearch = useFilterSearch(url, id);
  const isClientSideFilter = !!filterSearch;

  return (
    <div className={`${namespace} ${namespace}--${id}`} id={`${namespace}--${id}`}>
      <div className={`${namespace}__wrapper`} id={id}>
        <div className={`${namespace}__description`}>
          <span className={`${namespace}__results`}>{results_text}</span>
          <HighlightLabel {...highlight} />
          {label && <StyledLabelWrapper className={`${namespace}__label`} {...label} />}
        </div>

        {actions.length > 0 &&
          actions.map((actionItem) => <ActionTopSwitchFilter key={actionItem?.id} action={actionItem} />)}

        {tooltip && (
          <FilterTooltip className={`${namespace}__tooltip`} tooltip={tooltip} side="bottom">
            <div className={`${namespace}__tooltip-trigger`} />
          </FilterTooltip>
        )}

        <AnimatedSwitch
          checked={is_on}
          href={url}
          onClick={filterSearch}
          size="small"
          updateOnCheckedChange={isClientSideFilter}
        />
      </div>
    </div>
  );
};

TopSwitchFilter.propTypes = {
  actions: array,
  highlight: shape().isRequired,
  id: string.isRequired,
  is_on: bool.isRequired,
  label: shape(),
  results_text: string.isRequired,
  tooltip: shape(),
  url: string.isRequired,
};

TopSwitchFilter.defaultProps = {
  actions: [],
  label: null,
  tooltip: null,
  results_text: '',
};

export default TopSwitchFilter;
